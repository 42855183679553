import {
  scrollToElement,
  sendBrowserAgnosticEvent,
  trackCustomUserAction,
} from "../../core/utils";
import initPayableCheckout from "../../commerce/PayableCheckout";
import initReCAPTCHAs from "../../core/reCAPTCHA";
import { initPasswordlessSignup } from "../../users/signup";

const TIP_MODE_NUMBER = "number";
const TIP_MODE_PERCENT = "percent";
const TIP_OTHER = "4";

const tipSelectorLabels = {
  [TIP_MODE_NUMBER]: {
    1: "$1.00",
    2: "$2.00",
    3: "$3.00",
    4: "Other",
  },
  [TIP_MODE_PERCENT]: {
    1: "10%",
    2: "15%",
    3: "20%",
    4: "Other",
  },
};

const tipSelectorAmounts = {
  [TIP_MODE_NUMBER]: {
    1: 1.0,
    2: 2.0,
    3: 3.0,
    4: "Other",
  },
  [TIP_MODE_PERCENT]: {
    1: 0.1,
    2: 0.15,
    3: 0.2,
    4: "Other",
  },
};

function getTipMode(amount) {
  let mode = TIP_MODE_PERCENT;
  if (amount < 10.0) {
    mode = TIP_MODE_NUMBER;
  }

  return mode;
}

function amountOrTipErrorsExist(amountField, tipField, minimum) {
  const parsedAmount = Number(amountField.value);
  if (parsedAmount === "" || Number.isNaN(parsedAmount) || parsedAmount < minimum) {
    return true;
  }

  const parsedTip = Number(tipField.value);
  return Number.isNaN(parsedTip) || parsedTip < 0;
}

function clearValidationErrors(validationErrorsElement) {
  while (validationErrorsElement.hasChildNodes()) {
    validationErrorsElement.removeChild(validationErrorsElement.lastChild);
  }
}

function addValidationError(
  validationErrorsElement,
  errorMessage,
  skipErrorsCheck = false,
) {
  if (!validationErrorsElement.querySelector("li") || skipErrorsCheck) {
    const validationError = document.createElement("li");
    validationError.innerHTML = errorMessage;
    validationErrorsElement.appendChild(validationError);
  }
}

function reselectTipOption(tipSelect) {
  /**
   * De-select and then select the currently selected option for the tip select element
   * to deal with a bug in Safari 16.4.
   *
   * See https://bugs.webkit.org/show_bug.cgi?id=255230 for bug details.
   */
  const originalValue = tipSelect.value;
  tipSelect.value = null;
  tipSelect.value = originalValue;
}

function resetTipAndOptions(tipField, tipSelect, tipOptions, tipValidationErrors) {
  clearValidationErrors(tipValidationErrors);
  tipOptions.forEach((tipOption) => {
    tipOption.innerText = tipSelectorLabels.percent[tipOption.value];
  });

  reselectTipOption(tipSelect);

  tipField.value = "";
}

function updateTipOptions(amount, tipOptions, tipSelect) {
  const tipMode = getTipMode(amount);
  tipOptions.forEach((tipOption) => {
    const newElementLabel = tipSelectorLabels[tipMode][tipOption.value];
    const newElementAmount = tipSelectorAmounts[tipMode][tipOption.value] * amount;
    if (tipOption.value !== TIP_OTHER) {
      if (tipMode === TIP_MODE_NUMBER) {
        tipOption.innerText = newElementLabel;
      } else {
        tipOption.innerText = `(${newElementLabel}) ${newElementAmount.toFixed(2)}`;
      }
    }
  });

  reselectTipOption(tipSelect);
}

function updateTip(tipField, amount, tipSelect) {
  const tipMode = getTipMode(amount);

  let newTipAmount;
  if (tipSelect.value !== TIP_OTHER) {
    if (tipMode === TIP_MODE_NUMBER) {
      newTipAmount = tipSelectorAmounts[tipMode][tipSelect.value];
    } else {
      newTipAmount = tipSelectorAmounts[tipMode][tipSelect.value] * amount;
    }

    tipField.value = `${newTipAmount.toFixed(2)}`;
  }
}

function clearTotal() {
  const totalCharge = document.querySelector(".total-charge span");
  if (totalCharge) {
    totalCharge.innerText = "$0.00";
    totalCharge.classList.add("text-note");
  }
}

function updateTotal(currency, amountField, tipField) {
  const parsedAmount = Number(amountField.value);
  const parsedTip = Number(tipField.value);
  const total = parsedAmount + parsedTip;

  if (!Number.isNaN(total) && total > 0) {
    const totalCharge = document.querySelector(".total-charge span");
    if (totalCharge) {
      totalCharge.innerText = `${currency} ${total.toFixed(2)}`;
      totalCharge.classList.remove("text-note");
    }
  } else {
    clearTotal();
  }
}

function getStateForHistory(event) {
  const url = new URL(event.detail.value);
  const params = new URLSearchParams(url.search);
  return params.toString();
}

export default async function initFundraising(props) {
  let lastHistoryState;

  window.addEventListener("popstate", (event) => {
    lastHistoryState = event.state;
    sendBrowserAgnosticEvent(
      document.querySelector("#donation-checkout-form"),
      "swapCheckoutStep",
    );
  });

  document.body.addEventListener("pushHistoryState", (event) => {
    const historyState = getStateForHistory(event);
    if (historyState !== lastHistoryState) {
      window.history.pushState(historyState, "");
    }
  });

  if (props.memorialIsExample) {
    return;
  }

  if (props.signupFormPresent) {
    window.initReCAPTCHAs = initReCAPTCHAs;
  }

  const initDonationForm = (form) => {
    initPasswordlessSignup();

    if (props.signupFormPresent) {
      if (window.grecaptcha) {
        initReCAPTCHAs();
      }
    }

    const anonymousInput = document.getElementById(props.formFieldIds.anonymous);
    const displayNameCheckbox = document.getElementById("display-name-checkbox");
    const displayNameInput = document.getElementById(props.formFieldIds.displayName);
    const amountField = document.getElementById(props.formFieldIds.amount);
    const tipField = document.getElementById(props.formFieldIds.tip);
    const recentContributionWarningEl = document.querySelector(
      ".recent-contribution-warning",
    );

    const amountValidationErrors = document.querySelector(".amount-validation-errors");
    const tipOptions = document.querySelectorAll(".tip-select option");
    const tipSelect = document.querySelector(".tip-select");
    const tipValidationErrors = document.querySelector(".tip-validation-errors");

    // Initialize total <span>
    updateTotal(props.currency, amountField, tipField);

    const updateDisplayNameAndAnonymousState = (e) => {
      if (e.currentTarget === anonymousInput) {
        if (anonymousInput.checked) {
          displayNameCheckbox.checked = false;
          displayNameInput.value = "";
        }
      } else if (e.currentTarget === displayNameCheckbox) {
        if (displayNameCheckbox.checked) {
          anonymousInput.checked = false;
        }
      }

      displayNameInput
        .closest(".display-name-container")
        .classList.toggle("d-none", !displayNameCheckbox.checked);
      displayNameInput.disabled = anonymousInput.checked;
      displayNameInput.classList.toggle("disabled", anonymousInput.checked);
    };

    displayNameCheckbox.checked =
      displayNameCheckbox.dataset.defaultDisplayName &&
      displayNameInput.value &&
      displayNameInput.value !== displayNameCheckbox.dataset.defaultDisplayName;

    displayNameCheckbox.addEventListener("input", updateDisplayNameAndAnonymousState);
    anonymousInput.addEventListener("input", updateDisplayNameAndAnonymousState);
    updateDisplayNameAndAnonymousState({ target: null });

    if (props.recentContributionAmount) {
      ["input", "paste"].forEach((event) => {
        amountField.addEventListener(event, () => {
          recentContributionWarningEl.classList.remove("d-none");
          recentContributionWarningEl.innerText =
            `You recently donated $${props.recentContributionAmount} to this fundraiser. ` +
            "This will be an additional donation.";
        });
      });
    }

    // If there are validation errors, cancel the form submit protection. For reasons
    // that aren't clear yet, we need to handle that here instead of when we prevent
    // the HTMX request from firing.
    form.addEventListener("submit", () => {
      if (amountOrTipErrorsExist(amountField, tipField, props.minimumDonationAmount)) {
        sendBrowserAgnosticEvent(form, "cancel-submit-protect");
        scrollToElement(document.querySelector(".errorlist li"), -120);
      }
    });

    // If there are validation errors, prevent the HTMX request from occuring by
    // canceling the event.
    form.addEventListener("htmx:beforeRequest", (event) => {
      if (amountOrTipErrorsExist(amountField, tipField, props.minimumDonationAmount)) {
        event.preventDefault();
      }
    });

    amountField.addEventListener("input", () => {
      if (!props.recentContributionAmount) {
        clearValidationErrors(amountValidationErrors);
      }

      // Remove commas and alpha characters as a simple filter.
      amountField.value = amountField.value.replace(/[a-z,]/i, "");

      const parsedAmount = Number(amountField.value);
      if (amountField.value === "") {
        addValidationError(
          amountValidationErrors,
          "Contribution amount must be a number",
        );
        resetTipAndOptions(tipField, tipSelect, tipOptions, tipValidationErrors);
        clearTotal();
      } else if (Number.isNaN(parsedAmount)) {
        addValidationError(
          amountValidationErrors,
          "Contribution amount must be a number",
        );
        resetTipAndOptions(tipField, tipSelect, tipOptions, tipValidationErrors);
        clearTotal();
      } else {
        updateTipOptions(parsedAmount, tipOptions, tipSelect);
        updateTip(tipField, parsedAmount, tipSelect);
        updateTotal(props.currency, amountField, tipField);
      }
    });

    amountField.addEventListener("blur", () => {
      const parsedAmount = Number(amountField.value);
      if (parsedAmount < props.minimumDonationAmount) {
        addValidationError(
          amountValidationErrors,
          `Contribution amount must be at least $${props.minimumDonationAmount}`,
        );
        resetTipAndOptions(tipField, tipSelect, tipOptions, tipValidationErrors);
        clearTotal();
      }
    });

    tipField.addEventListener("input", () => {
      clearValidationErrors(tipValidationErrors);

      // Remove commas and alpha characters as a simple filter.
      tipField.value = tipField.value.replace(/[a-z,]/i, "");

      const parsedTip = Number(tipField.value);
      if (Number.isNaN(parsedTip)) {
        addValidationError(
          tipValidationErrors,
          "Tip amount must be zero or a positive number",
        );
        clearTotal();
      } else if (parsedTip < 0) {
        addValidationError(
          tipValidationErrors,
          "Tip amount must be zero or a positive number",
        );
        clearTotal();
      } else {
        updateTotal(props.currency, amountField, tipField);

        const parsedAmount = Number(amountField.value);
        if (parsedAmount > 0 && parsedAmount === parsedTip) {
          addValidationError(
            tipValidationErrors,
            "Just checking: Did you intend to leave a 100% tip for Ever Loved? If so, awesome&mdash;thanks for supporting our website!",
            true,
          );
        }
      }
    });

    tipField.addEventListener("blur", () => {
      if (tipField.value === "") {
        tipField.value = "0";
      }
    });

    const tipContainerInner = document.querySelector(".tip-container-inner");
    tipSelect.addEventListener("input", () => {
      clearValidationErrors(tipValidationErrors);
      const parsedAmount = Number(amountField.value);

      if (tipSelect.value === TIP_OTHER) {
        tipContainerInner.classList.remove("d-none");
      } else if (
        Number.isNaN(parsedAmount) ||
        parsedAmount < props.minimumDonationAmount
      ) {
        tipContainerInner.classList.add("d-none");
        resetTipAndOptions(tipField, tipSelect, tipOptions, tipValidationErrors);
        clearTotal();
      } else {
        tipContainerInner.classList.add("d-none");
        updateTip(tipField, parsedAmount, tipSelect);
        updateTotal(props.currency, amountField, tipField);
      }
    });

    document.querySelectorAll("input, select").forEach((checkoutField) => {
      checkoutField.addEventListener("blur", (event) => {
        if ((event.currentTarget.value || "").length > 0) {
          trackCustomUserAction(
            window.location.href,
            "donation checkout",
            event.currentTarget.getAttribute("id"),
          );
        }
      });
    });

    sendBrowserAgnosticEvent(tipSelect, "input");
    if (amountField.value) {
      sendBrowserAgnosticEvent(amountField, "input");
    }
  };

  const form = document.querySelector("form#donation-checkout-form");
  if (form) {
    await initDonationForm(form);
  }

  document.body.addEventListener("htmx:afterSettle", (event) => {
    if (
      event.detail.elt.tagName.toLowerCase() === "form" &&
      event.detail.elt.id === "donation-checkout-form"
    ) {
      initDonationForm(event.detail.elt);
    }
  });

  await initPayableCheckout(props);
}
